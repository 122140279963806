:root {
  /* colors */
  --MainBg: #fff;
  --SecondaryBg: #4685ff;
  --MainText: #fff;
  --PageEx: #ffeaea;
  --LogoColor: #ffb084;
  --TextAccent: #000;
  --FormError: #ffee00;
  --MainBgLines: rgba(70, 133, 255, 0.25);
  --FormInputPlaceholder: rgba(255, 255, 255, 0.6);
  --FormMain: #422800;
  --FormHover: #fff;
  --FormSoft: #fbeee0;

  /* stipling */
  --stiple-bg: #4685ff;
  --stiple-color: #162f61;
  --stiple-size: 2px;
  --stiple-space: 12px;

  /* visual */
  --BoxShadow: -10px 10px 10px 10px rgba(0, 0, 0, 0.25);
  --FormInputBorder: 1px solid rgba(0, 0, 0, 0.68);
  --FormInputBg: rgba(217, 217, 217, 0.2);

  /* background images */
  /*   --HomeHero: url(../data/images/happyOldPeople1.jpg); */

  /* borders */
  --smallBorder: solid 1px #000;
}
* {
  box-sizing: border-box;
}
/* helps width 100% work the way youd think it would ^*/

/* fonts */

.welcome-bg-letter,
.main-page-bg-letters,
h2,
.inspire-services,
.projects-link-container a,
.bigWord-rules {
  text-align: center;
  font-family: "Abril Fatface";
  font-weight: 400;
}

.normal-text,
.internal-link,
.welcome-main :nth-child(2),
::placeholder,
.form-error-message,
form button,
.social-combo p {
  font-family: "Abyssinica SIL";
  font-weight: 400;
}

.welcome-main,
.welcome-secondary {
  font-family: "Abril Fatface";
}

.normal-text {
  text-align: center;
  line-height: 200%;
  margin-right: 10%;
}

.dream-text-size {
  font-size: 20px;
  line-height: 1.5;
}

.welcome-bg-letter,
.main-page-bg-letters {
  color: var(--LogoColor);
  text-shadow: -10px 10px 10px #000;
}

.form-error-message {
  color: var(--LogoColor);
}

.welcome-main .welcome-header,
.welcome-secondary p {
  font-size: clamp(1rem, 6vw, 64px);
}

.welcome-main p,
.welcome-main nav,
.internal-link,
.normal-text,
form button {
  font-size: clamp(1rem, 2vw, 20px);
}

.welcome-secondary :nth-child(2) {
  font-size: clamp(1rem, 9vw, 96px);
}

.bigWord-rules {
  font-size: clamp(1rem, 20vw, 230px);
}

.welcome-bg-letter {
  font-size: clamp(1rem, 70vw, 700px);
}

.main-page-bg-letters {
  font-size: clamp(1rem, 90vw, 1400px);
}

h2,
.projects-link-container a {
  font-size: clamp(1rem, 4vw, 40px);
}

.inspire-services {
  font-size: clamp(1rem, 3.2vw, 32px);
}

/* everywhere usage */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  color: white;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

ul,
a {
  list-style-type: none;
  text-decoration: none;
  border-bottom: white 1px solid;
}

a {
  display: block;
}

.internal-link {
  color: white;
}

.fcfc {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.frfc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stipling {
  background: linear-gradient(
        90deg,
        var(--stiple-bg) calc(var(--stiple-space) - var(--stiple-size)),
        transparent 1%
      )
      center / var(--stiple-space) var(--stiple-space),
    linear-gradient(
        var(--stiple-bg) calc(var(--stiple-space) - var(--stiple-size)),
        transparent 1%
      )
      center / var(--stiple-space) var(--stiple-space),
    var(--stiple-color);

  z-index: 1;
}

.social-info {
  margin-top: 15px;
  gap: 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.social-combo {
  display: flex;
  gap: 15px;
}

.img-link {
  width: 50px;
}

.welcome,
.home-content-container,
.below-inspire-bigword .inspire-secondary,
.inspire-secondary-below {
  position: relative;
}

.content-block {
  width: 100%;
  max-width: 1500px;
  margin-bottom: 50px;
}

.raised-container {
  background-color: var(--SecondaryBg);
  box-shadow: var(--BoxShadow);
}

.hidden {
  display: none;
}

main {
  background: repeating-linear-gradient(
    -25deg,
    transparent,
    transparent 180px,
    var(--MainBgLines) 180px,
    var(--MainBgLines) 181px
  );
}

.home {
  background: repeating-linear-gradient(
    -25deg,
    transparent,
    transparent 500px,
    var(--MainBgLines) 500px,
    var(--MainBgLines) 501px
  );
}

/* header */
.header-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding-left: 0;
  width: 1000px;
}

header {
  height: 120px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: top 0.7s;
}

.hide-header {
  top: -120px;
}

header div {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.header-logo {
  margin-right: 30px;
}

header > div > a {
  border: none;
}

/* home */
.welcome {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
}

.welcome-secondary {
  text-align: center;
}

.welcome-main,
.welcome-secondary {
  width: 40%;
}

.welcome-secondary p {
  margin: 0;
}

.welcome-secondary :nth-child(1) {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 24.55%,
    #fff 155.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome-secondary :nth-child(3) {
  background: linear-gradient(
    180deg,
    #fff -41.82%,
    rgba(255, 255, 255, 0) 73.64%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.triple-header {
  user-select: none;
  cursor: default;
}

.welcome-bg-letter {
  z-index: -1;
  position: absolute;
  left: 15%;
  user-select: none;
  cursor: default;
}

nav {
  display: flex;
}

.projects-link-container nav {
  justify-content: center;
}

.main-page-bg-letters {
  display: flex;
  width: 100%;
  height: 946px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin: 0;
}

.main-page-bg-letters-container :nth-child(1) {
  margin-bottom: 120px;
}

.main-page-bg-letters-container {
  position: absolute;
}

.reviews {
  height: 450px;
}

.contact-us {
  height: 950px;
}

.contact-us .dream {
  margin-top: 70px;
}

.dream {
  margin-top: 100px;
}

.dream-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--SecondaryBg);
  width: 53%;
  max-width: 660px;
  padding: 50px 0 50px 0;
  box-shadow: var(--BoxShadow);
}

.dream-content {
  min-height: 420px;
  width: 100%;
}

.dream-text {
  width: 40%;
}

.dream .normal-text {
  width: 60%;
}

.dream-img {
  height: 80%;
  width: 80%;
  object-fit: contain;
}

.dream h2,
.inspire h2,
.bigWord-rules {
  text-align: start;
}

.reviews .raised-container {
  width: 90%;
  height: 80%;
}

.reviews .normal-text {
  width: 80%;
  text-align: center;
}

.inspire-content {
  padding-top: 50px;
}

.inspire-primary {
  width: 30%;
}

.inspire-secondary {
  height: 400px;
  width: 55%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.below-inspire-bigword {
  width: 100%;
}

.inspire-secondary-below {
  padding: 25px 0 25px 0;
  margin-bottom: 80px;
  width: 40%;
}

.below-inspire-bigword .normal-text:nth-of-type(2) {
  margin-top: 110px;
}

.inspire {
  gap: 5%;
}

.theme-ex {
  width: 100%;
  height: 100%;
}

.theme-ex button:nth-child(1) {
  margin-right: 10px;
}

.theme-ex button:nth-child(3) {
  margin-left: 10px;
}

.theme-ex img {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us .raised-container {
  width: 90%;
  height: 90%;
  /*   max-height: 950px; */
  justify-content: start;
}

.projects-link-container {
  width: 100%;
  height: 40px;
}

/* contactForm */

.contact-form {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  height: 90%;
}

/* .service-chunk {
  display: flex;
  flex-flow: column;
} */

.service-chunk p {
  text-align: start;
}

.user-name-area {
  display: flex;
  justify-content: space-between;
}

.user-info-fields {
  margin-top: 50px;
}

.user-info-fields input,
.user-info-fields textarea {
  width: 100%;
  height: 35px;
  color: white;
  border-radius: 8px;
  border: var(--FormInputBorder);
  background: var(--FormInputBg);
}
::placeholder {
  color: var(--FormInputPlaceholder);
  padding: 5px 0 0 5px;
}

#first-name,
#last-name {
  width: 95%;
}

.user-name-area > :nth-child(2) {
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
}

#user-description {
  height: 200px;
  resize: none;
}

button {
  border-radius: 5px;
  border: none;
}

button:active {
  background-color: rgba(211, 69, 69, 0.39);
}

button {
  background-color: var(--FormSoft);
  border: 2px solid var(--FormMain);
  border-radius: 30px;
  box-shadow: var(--FormMain) 4px 4px 0 0;
  color: var(--FormMain);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:hover {
  background-color: var(--FormHover);
}

button:active {
  box-shadow: var(--FormMain) 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-74 {
    min-width: 120px;
    padding: 0 25px;
  }
}

/* oops */

.oops {
  margin-top: 300px;
  color: var(--TextAccent);
}

.oops a {
  color: var(--TextAccent);
}

/* footer */

footer {
  height: 300px;
  background-color: var(--SecondaryBg);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px;
  margin-top: auto;
  box-shadow: var(--BoxShadow);
}

.bigWord-rules {
  margin: 0 0 20px 10px;
  letter-spacing: 0.25em;
  line-height: 0.8;
}

.inspire .bigWord-rules {
  line-height: 1;
  margin: 0 0 -10px 10px;
}

.bigWord-coloring {
  mix-blend-mode: difference;
  background: linear-gradient(#e66465, #9198e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1400px) {
  .bigWord-rules {
    font-size: clamp(1rem, 16vw, 160px);
  }
}

@media screen and (max-width: 1300px) {
  .content-block {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .inspire-primary {
    width: 40%;
  }
}

@media screen and (max-width: 650px) {
  .five-page .n1,
  .five-page .n3,
  .five-page .n0,
  .five-page .n4,
  .three-page .n0,
  .three-page .n2 {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .inspire-services-container {
    grid-template-columns: 1fr;
  }

  .reviews {
    height: 600px;
  }

  .reviews .raised-container {
    height: 500px;
  }
}
